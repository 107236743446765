import { Injectable } from '@angular/core';
import { GraphqlService } from '../utils/graphql.service';
import { Storage } from '@ionic/storage';
import { Subject } from 'rxjs';
import { MiscService } from './misc.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  graphql: GraphqlService = null;
  misc: MiscService = null;
  storage: Storage = null;
  subject = new Subject<any>();

  constructor(
    graphql: GraphqlService,
    misc: MiscService,
    storage: Storage
  ) {
    this.graphql = graphql;
    this.misc = misc;
    this.storage = storage;

    this.init();
  }

  async init() {
    const token = await this.storage.get('customer_token');
    if (!token) {
      return;
    }
    this.customerInfo();
  }

  async login(customerData: {[k: string]: any}, isNew: boolean = false) {
    let data = await this.graphql.mutate(`
      {
        generateCustomerToken(
          email: "${customerData.email}"
          password: "${customerData.password}"
        ) {
          token
        }
      }
    `);
    if (data?.data?.generateCustomerToken?.token) {
      await this.storage.set('customer_token', data?.data?.generateCustomerToken?.token);
      await this.customerInfo(isNew);
    }
    this.subject.next(data);

    return data;
  }

  async resetPassword(customer, token: string) {
    let data = await this.graphql.mutate(`
      {
        resetPassword(
          email: "${customer.email}",
          resetPasswordToken: "${token}",
          newPassword: "${customer.password}"
        )
      }
    `);
    return data;
  }

  async requestPasswordReset(customerData: {[k: string]: any}) {
    let data = await this.graphql.mutate(`
      {
        requestPasswordResetEmail(
          email: "${customerData.email}"
        )
      }
    `);
    return data;
  }

  async logout() {
    let data = await this.graphql.mutate(`
      {
        revokeCustomerToken {
          result
        }
      }
    `);

    await this.storage.remove('customer_token');
    await this.storage.remove('customer_info');
    await this.storage.remove('cart_id');
    document.cookie = 'PHPSESSID=;path=/;domain=.' + document.location.host;

    this.subject.next(data);
    this.misc.loggedOut();

    return data;

  }

  async register(customerData: {[k: string]: any}) {
    let mutation = `
      CreateCustomer (
        $email: String!
        $firstname: String!
        $lastname: String!
        $password: String!
      ) {
        createCustomerV2(
          input: {
            email: $email,
            firstname: $firstname,
            lastname: $lastname,
              password: $password
          }
        ) {
          customer {
            company
            created_at
            email
            firstname
            gender
            is_subscribed
            lastname
            middlename
            position
            prefix
            suffix
            taxvat
          }
        }
      }
    `;
    const vars: any = {
      firstname: customerData.firstname,
      lastname: customerData.lastname,
      email: customerData.email,
      password: customerData.password,
      is_subscribed: customerData.newsletter
    }    
    let data = await this.graphql.mutate(mutation, true, true, vars, 'default');
    if (data?.data?.createCustomerV2?.customer.email) {
      await this.login(customerData, true);
    }
    return data;
  }

  async update(customerData: {[k: string]: any}) {
    let mutation = `
      UpdateCustomer(
        $firstname: String!
        $lastname: String!
        $company: String!
        $position: String!
        $is_subscribed: Boolean!
      ) {
        updateCustomerV2(
          input: {
            firstname: $firstname
            lastname: $lastname
            position: $position
            company: $company
            is_subscribed: $is_subscribed
          }
        ) {
          customer {
            firstname
            lastname
            email
            company
            position
            is_subscribed
          }
        }
      }
    `;
    const vars: any = {
      firstname: customerData.firstname,
      lastname: customerData.lastname,
      company: customerData.company,
      position: customerData.position,
      is_subscribed: customerData.newsletter
    }
    let data = await this.graphql.mutate(mutation, true, true, vars, 'default');
    await this.customerInfo();

    return data;
  }
  async customerInfo(isNew: boolean = false) {
    let data = await this.graphql.query(`
      {
        customer {
          firstname
          lastname
          company
          position
          suffix
          email
          is_subscribed
          wishlists {
            id
            items_count
            items_v2 {
              items {
                id
                product {
                  uid
                  name
                  sku
                }
              }
            }
          }
          addresses {
            firstname
            lastname
            street
            city
            region {
              region_code
              region
            }
            postcode
            country_code
            telephone
          }
          reviews(pageSize: 10000, currentPage: 1) {
            items {
              average_rating
              created_at
              nickname
              summary
              text
              product {
                sku
              }
            }
            page_info {
              current_page
              page_size
              total_pages
            }
          }
          orders(pageSize: 100000, currentPage: 1) {
            total_count
            items {
              id
              items {
                product_sku
                product_type
                product_name
                product_url_key
                ... on DownloadableOrderItem {
                  id
                  link_hashes
                }
                ... on BundleOrderItem {
                  id
                  bundle_options {
                    id
                    uid
                    label
                    values {
                      id
                      uid
                      product_name
                      product_sku
                    }
                  }
                }
              }
            }
          }
        }
      }
    `, false);
    if (isNew) {
      data.data.customer.is_new = true;
    }
    if (data?.data?.customer) {
      await this.storage.set('customer_info', data.data.customer);
    }
    if (data?.errors) {
      await this.logout();
    }

    this.subject.next(data);
  }
}
