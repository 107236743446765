import { Injectable } from '@angular/core';
import { GraphqlService } from './graphql.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  graphql: GraphqlService = null;
  constructor(graphql: GraphqlService)
  {
    this.graphql = graphql;
  }

  async getReviewsMetaData() {
    const data = await this.graphql.query(`
      {
        productReviewRatingsMetadata {
          items {
            id
            name
            values {
              value_id
              value
            }
          }
        }
      }
    `);
    return data;
  }

  async createReview(review) {
    let ratings = [];
    for (const rating of Object.keys(review.ratings)) {
      ratings.push(`{ id: "${rating}", value_id: "${review.ratings[rating]}"}`);
    }
    const data = await this.graphql.mutate(`
      {
        createProductReview(
          input: {
            sku: "${review.sku}",
            nickname: "${review.nickname}",
            summary: "${review.summary}",
            text: "${review.text}",
            ratings: [
              ${ratings.join(', ')}
            ]
          }
      ) {
          review {
            nickname
            summary
            text
            average_rating
            ratings_breakdown {
              name
              value
            }
          }
        }
      }
    `);
    return data;
  }

  async searchAsYouType(q: string) {
    const data = await this.graphql.query(`
      {
        products(search: "${q}", pageSize: 8) {
          total_count
          items {
            name
            sku
            url_key
            image {
              label
              url
              position
              disabled
            }
          }
        }
      }
    `, false, true, true);
    return data;
  }

  async getProducts(
    filters: {[k: string]: any} = {},
    page: number = 1,
    bannerBlockId: string = null,
    useCache: boolean = true
  ) {
    let searchQuery = '';
    if (Object.keys(filters).indexOf('q') !== -1) {
      searchQuery = `search: "${filters.q}"`;
      delete filters.q;
    }
    let categoriesQuery = '';
    if (Object.keys(filters).indexOf('category_uid') !== -1) {
      categoriesQuery = `
        categories(
          filters: {
            category_uid: {in: ["${filters.category_uid.split(',').join('","')}"]}
          }
          pageSize: 1
          currentPage: 1
        ) {
          items {
            uid
            level
            name
            url_key
            path
            image
            description
            display_mode
            cms_block {
              identifier
              content
              title
            }
            breadcrumbs {
              category_id
              category_level
              category_name
              category_uid
              category_url_key
              category_url_path
            }
          }
        }
      `;
    }
    const formattedFilters = [];
    for (const key of Object.keys(filters)) {
      if (filters[key].length === 0) {
        continue;
      }
      formattedFilters.push(`${key}: {in: ["${filters[key].replace(/,/g, '","')}"]}`);
    }
    let data = this.graphql.query(`
      {
        ${categoriesQuery}
        aggregations: products(
          ${searchQuery}
          ${filters?.category_uid ? `filter: { category_uid: { in: ["${filters.category_uid.split(',').join('","')}"] } }` : ''}
          pageSize: 1
          currentPage: 1
        ) {
          aggregations {
            attribute_code
            count
            label
            options {
                count
                label
                value
            }
          }
        }
        products(
          ${searchQuery}
          filter: {
            ${formattedFilters.join("\n")}
          }
          pageSize: 20
          currentPage: ${page}
          sort: { position: DESC }
        ) {
          total_count
          items {
            name
            sku
            url_key
            attribute_set_code
            meta_keyword
            review_count
            rating_summary
            media_gallery {
              url
              ... on ProductVideo {
                video_content {
                  media_type
                  video_provider
                  video_url
                  video_title
                  video_description
                  video_metadata
                }
              }
            }
            reviews(pageSize: 100, currentPage: 1) {
              items {
                average_rating
                created_at
                nickname
                summary
                text
                product {
                  sku
                }
              }
              page_info {
                current_page
                page_size
                total_pages
              }
            }
            description {
              html
            }
            image {
              label
              url
              position
              disabled
            }
            thumbnail {
              label
              url
              position
              disabled
            }
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
              }
            }
            ... on DownloadableProduct {
              dimensions_hires
              dimensions_max
              filesize
              file_format
              orientation
              video_duration
              video_framerate
              video_resolution
              quality
              country
              region
              destination
              location
              language
              flipbook_url
              downloadable_product_samples {
                sample_url
                title
              }
              downloadable_product_links {
                id
                uid
                price
                sample_url
                title
              }
            }
            ... on BundleProduct {
              events: items {
                sku
                title
                type
                uid
                option_id
                required
                options {
                  can_change_quantity
                  is_default
                  label
                  position
                  price
                  price_type
                  quantity
                  uid
                  id
                  product {
                    sku
                    event_date
                    event_url
                    event_contact
                    event_contact_email
                    event_duration
                    short_description {
                      html
                    }
                    meta_title
                    ... on VirtualProduct {
                      options {
                        option_id
                        required
                        sort_order
                        title
                        uid
                        ... on CustomizableDropDownOption {
                          value {
                            option_type_id
                            price
                            price_type
                            sku
                            uid
                            sort_order
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          suggestions {
            search
          }
          page_info {
            page_size
            current_page
            total_pages
          }
          aggregations {
            attribute_code
            count
            label
            options {
                count
                label
                value
            }
          }
        }
        cmsBlocks(identifiers: [
          ${bannerBlockId ? '"banner-' + bannerBlockId.toLowerCase() + '",' : ''}
          "banner-default"
          ]
        ) {
          items {
            identifier
            title
            content
          }
        }
      }
    `, true, true, useCache);
    return data;
  }
}
