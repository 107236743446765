import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { CoreService } from 'src/app/utils/core.service';
import { ProductService } from 'src/app/utils/product.service';
import { CartService } from 'src/app/utils/cart.service';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { MiscService } from 'src/app/utils/misc.service';
import * as Mime from 'mime';

@Component({
  selector: 'bw-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @ViewChild(IonModal) modal: IonModal;

  core:CoreService = null;
  product: ProductService = null;
  cart: CartService = null;
  router: Router = null;
  toast: ToastController = null;
  misc: MiscService = null;

  results = [];

  downloading: boolean = false;
  categories = null;

  search: {[k: string]: any} = {
    q: null
  }

  errors: {[k: string]: any} = {
    q: null
  }

  constructor(
    core: CoreService,
    product: ProductService,
    cart: CartService,
    router: Router,
    toast: ToastController,
    misc: MiscService
  ) {
    this.core = core;
    this.product = product;
    this.cart = cart;
    this.router = router;
    this.toast = toast;
    this.misc = misc;
  }

  ngOnInit() {}

  cancel() {
    this.modal.dismiss();
  }

  async searchAsYouType(event) {
    this.results = [];
    this.search.q = event.target.value;
    this.errors.q = null;
    if (!this.search.q || this.search.q.length < 3) {
      this.errors.q = 'Please type at least 3 characters';
      return;
    }
    let data = await this.product.searchAsYouType(this.search.q);
    if (data?.data?.products?.items) {
      this.results = data.data.products.items;
    }
  }
  doSearch() {
    this.errors.q = null;
    if (!this.search.q || this.search.q.length < 3) {
      this.errors.q = 'Please type at least 3 characters';
      return;
    }
    this.modal.dismiss();
    this.results = [];
    this.router.navigateByUrl('/catalogsearch/results/?q=' + this.search.q);
  }

  goto(item) {
    this.modal.dismiss();
    this.router.navigateByUrl(item.url_key);
  }

  async checkout() {
    if (!this.cart?.itemCount) {
      return;
    }
    const loggedIn = await this.cart.checkLoggedIn();
    if (!loggedIn) {
      this.misc.subject.next('showLogin');
      const toast = await this.toast.create({
        header: 'Download',
        message: 'You must be logged in to perform that action.',
        icon: 'close-circle',
        color: 'danger',
        duration: 2000
      });
      toast.present();
      return;
    }

    this.downloading = true;
    const items = await this.cart.storage.get('cart_items');
    for (const item  of items) {
      await this.cart.addToCart(item);
    }

    const cartId = await this.cart.getCartId();
    const order = await this.cart.checkout(cartId);

    if (order?.errors) {
      const messages = [];
      for (let error of order.errors) {
        messages.push(error.message);
      }
      messages.filter((item, index) => messages.indexOf(item) === index);
      const toast = await this.toast.create({
        header: 'Checkout',
        message: messages.join(' '),
        icon: 'close-circle',
        color: 'danger',
        duration: 2000
      });
      toast.present();
      this.downloading = false;

      return;
    }

    const orderNumber = order.data.placeOrder.order.order_number;
    const orderDetails = await this.cart.getOrder(loggedIn ? orderNumber : cartId);
    await this.cart.storage.remove('cart_id');
    await this.cart.storage.remove('cart_items');
    this.cart.itemCount = 0;
    const myDownloads = orderDetails.items[0].items;
    for (const myDownload of myDownloads) {
      for (let linkHash of myDownload.link_hashes) {
        const url = this.core.storeConfig.downloadable_link_url + linkHash;

        let headers: {[k: string]: any} = {};
        const token = await this.cart.storage.get('customer_token');
        if (token) {
          headers = {
            method: 'get',
            headers: new Headers({
              'Authorization': 'Bearer ' + token
            })
          }
        }
        const result = await fetch(url, headers);
        const data = await result.blob();
        const blob = new Blob([data], { type: data.type || 'application/octet-stream' });
        let fileExtension = 'jpg';
        if (data.type) {
          // @ts-ignore
          fileExtension = Mime.getExtension(data.type);
          if (!fileExtension) {
            const pieces = data.type.split('/');
            fileExtension = pieces.pop();
          }
        }
        var blobURL = URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', `${myDownload.product_sku}.${fileExtension}`);
        tempLink.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(blobURL);
        }, 100);
      }
    }
    this.downloading = false;
  }
}
