import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GraphqlService {

  API_URL = environment.backendUrl + environment.apiUrl;

  storage: Storage = null;

  isLoading: boolean = false;

  loadingCtrl: LoadingController = null;
  loader: HTMLIonLoadingElement = null;
  constructor(
    loadingCtrl: LoadingController,
    storage: Storage
  ) {
    this.loadingCtrl = loadingCtrl;
    this.storage = storage;
  }

  async query(
    queryString: string,
    showLoader: boolean = true,
    useCustomerToken: boolean = true,
    useCache: boolean = false,
    vars: any = null,
    storeCode: string = 'en'
  ) {
    if (showLoader && !this.loader && !this.isLoading) {
      this.isLoading = true;
      this.loader = await this.loadingCtrl.create();
      this.loader.present();
    }
    const headers: {[k: string]: any} = {
      'Content-Type': 'application/json',
      'store': storeCode
    };
    let token = null;
    if (useCustomerToken) {
      token = await this.storage.get('customer_token');
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
    }
    if (token) {
      queryString += `
      # ${token}
      `;
    }
    if (useCache && environment.useCache) {
      const data = await fetch(this.API_URL + '?' + btoa(queryString), {
        method: 'GET',
        headers: headers
      })
      .then(res => {
        return res.json();
      })
      .catch(e => {
        console.warn(e);
        return {};
      });
      if (showLoader && this.loader) {
        this.isLoading = false;
        this.loader.dismiss().then(this.loader = null);
      }
      return data;
    }

    const postBody: any = { query: queryString }
    if (vars) {
      postBody.variables = vars;
    }
    const data = await fetch(this.API_URL, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(postBody),
    })
    .then(res => {
      return res.json();
    })
    .catch(e => {
      console.warn(e);
      return {};
    });
    if (showLoader && this.loader) {
      this.isLoading = false;
      this.loader.dismiss().then(this.loader = null);
    }
    return data;
  }

  async mutate(
    queryString: string,
    showLoader: boolean = true,
    useCustomerToken: boolean = true,
    vars: any = null,
    storeCode: string = 'en'
  ) {
    return await this.query(`mutation ${queryString}`, showLoader, useCustomerToken, false, vars, storeCode);
  }
}
