import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MiscService {

  subject = new Subject<any>();
  details: {[k: string]: any} = {};

  constructor() {
  }

  showRegister() {
    this.details.selectedSegment = 'register';
    document.location.hash = '';
    this.subject.next('showRegister');
  }
  showLogin() {
    this.subject.next('showLogin');
  }

  hideLogin() {
    this.subject.next('hideLogin');
  }

  loggedIn() {
    this.subject.next('loggedIn');
  }
  loggedOut() {
    this.subject.next('loggedOut');
  }

  async pageView(url: string, title: string = null) {
    try {
      // Register Page View

      console.log('PAGE VIEW', url, title, environment.gaId);

      window.gtag('config', environment.gaId, {
        'page_title' : title || 'Home Page',
        'page_path': url
       });
    } catch (e) {
    }
  }
}
