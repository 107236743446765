import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { CoreService } from 'src/app/utils/core.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'bw-menu-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class MenuListComponent implements OnInit {

  @Input() items;
  @Input() parent;
  @Input() isDesktop: boolean = false;

  active: Number = null;

  private core: CoreService = null;
  private router: Router = null;
  private menuController: MenuController = null;

  menuHideTimeout = null;

  constructor(
    core: CoreService,
    router: Router,
    menuController: MenuController
  ) {
    this.core = core;
    this.router = router;
    this.menuController = menuController;
   }

  ngOnInit() {
    if (!this.active && this.parent.level === 1) {
      this.active = this.parent.id;
    }
    if (!environment.isLive) {
      this.items = [];
    }
  }

  goto(url: string) {
    if (this.isDesktop) {
      this.hideAllMenus(null);
    } else {
      this.menuController.close();
    }
    this.router.navigateByUrl(url);
  }

  toggleMenu(event, itemId: Number, parentId: Number, item: any = null) {
    event.stopPropagation();
    event.preventDefault();
    if (item?.image) {
      const categoryImageHolder = document.querySelector<HTMLElement>('.desktop-menu-category-image');
      if (categoryImageHolder) {
        categoryImageHolder.style.cssText = `background-image: url('${this.core.mediaUrl(item.image)}'); display: block;`;
      }
    }
    clearTimeout(this.menuHideTimeout);
    let selector = this.isDesktop ? 'desktop-menu-' : 'mobile-menu-';
    let element = document.getElementById(selector + 'menu-' + itemId);
    if (this.isDesktop) {
      for (const entry of element.classList.entries()) {
        const [key, value] = [...entry];
        if (value.indexOf('menu-level-') === 0) {
          const currentLevel = parseInt(value.replace('menu-level-', ''));
          const elementsToHide = document.querySelectorAll<HTMLElement>('.desktop-menu [class*="menu-level-"]');
          for (const elementToHide  of elementsToHide) {
            for (const c of elementToHide.classList.entries()) {
              const [k, v] = [...c];
              if (v.indexOf('menu-level-') === 0) {
                const level = parseInt(v.replace('menu-level-', ''));
                if (elementToHide.id !== 'desktop-menu-menu-' + itemId && level >= currentLevel) {
                  elementToHide.style.display = 'none';
                  if (this.active === itemId) {
                    this.active = null;
                  }

                  break;
                }
              }
            }
          }
          break;
        }
      }
    }
    if (this.active === itemId) {
      this.active = parentId;
      element.style.display = 'none';
      return;
    }
    this.active = itemId;
    element.style.display = 'block';
  }

  hideAllMenus(e) {
    if (e) {
      var target = e.toElement || e.relatedTarget || e.target;
      if (target && target.closest('.desktop-menu')) {
        return;
      }
    }
    this.menuHideTimeout = setTimeout(function() {
      const elementsToHide = document.querySelectorAll<HTMLElement>('.desktop-menu [class*="menu-level-"]');
      for (const elementToHide  of elementsToHide) {
        elementToHide.style.display = 'none';
      }
      const categoryImageHolder = document.querySelector<HTMLElement>('.desktop-menu-category-image');
      if (categoryImageHolder) {
        categoryImageHolder.style.display = 'none';
      }
      this.active = null;
    }, 200);
  }

  isActive(urlPath: string): string {
    if (urlPath === '') {
      return window.location.pathname === '/' ? 'active' : '';
    }
    return window.location.pathname.indexOf('/' + urlPath) === 0 ? 'active' : '';
  }
}
