import { Injectable } from '@angular/core';
import { GraphqlService } from '../utils/graphql.service';
import { CustomerService } from './customer.service';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  graphql: GraphqlService = null;
  storage: Storage = null;
  customer: CustomerService = null;

  itemCount: 0;

  constructor(
    graphql: GraphqlService,
    customer: CustomerService,
    storage: Storage
  ) {
    this.graphql = graphql;
    this.storage = storage;
    this.customer = customer;
  }

  async checkLoggedIn() {
    const token = await this.storage.get('customer_token');
    return token ? true : false;
  }

  async createEmptyCart(): Promise<string> {
    const data = await this.graphql.mutate(
      `
        {
          createEmptyCart
        }
      `,
      false,
      true
    );
    return data.data.createEmptyCart;
  }

  async mergeCart(cartId: string): Promise<string> {
    const customerCartId = await this.createEmptyCart();
    await this.graphql.mutate(
      `
        mutation {
          mergeCarts(
            source_cart_id: "${cartId}"
            destination_cart_id: "${customerCartId}"
          ) {
            items {
              id
            }
          }
        }
      `,
      false
    );
    return customerCartId;
  }

  async getCartId(useTemporaryCartId: boolean = false): Promise<string> {
    if (useTemporaryCartId) {
      return await this.createEmptyCart();
    }
    let cartId = await this.storage.get('cart_id');
    if (cartId) {
      return cartId;
    }
    cartId = await this.createEmptyCart();
    await this.storage.set('cart_id', cartId);
    return cartId;
  }
  async applicationCart(item: any = null) {
    const currentItems = await this.storage.get('cart_items') || [];
    if (item) {
      const newItems = [];
      let itemInCart = false;
      for (const currentItem of currentItems) {
        if (currentItem.sku === item.sku) {
          itemInCart = true;
          continue;
        }
        newItems.push(currentItem);
      }
      if (!itemInCart) {
        newItems.push(item);
      }
      await this.storage.set('cart_items', newItems);
    }
    return await this.storage.get('cart_items');
  }

  async addToCart(
    item: any,
    showLoader: boolean = true,
    useCustomerToken: boolean = true,
  ) {
    const cartId = await this.getCartId();

    if (item.attribute_set_code === 'Events') {
      const privateSessionItem = item.events[0].options.find(option => option?.privateSession ? true : false);
      if (privateSessionItem) {
        const data = await this.graphql.mutate(`
          {
            addProductsToCart(
              cartId: "${cartId}"
              cartItems: [
                {
                  sku: "${privateSessionItem.selectedSlot.sku}"
                  quantity: 1
                  selected_options: [
                    "${privateSessionItem.selectedSlot.uid}"
                  ]
                }
              ]
            ) {
              cart {
                items {
                  uid
                  quantity
                  product {
                    sku
                  }
                }
              }
            }
          }
        `, showLoader, useCustomerToken);
        return data;
      }
      const data = await this.graphql.mutate(`
        {
          addProductsToCart(
            cartId: "${cartId}"
            cartItems: [
              {
                sku: "${item.sku}"
                quantity: 1
                selected_options: [
                  "${item.selectedEventDate.option}"
                ]
              }
            ]
          ) {
            cart {
              items {
                uid
                quantity
                product {
                  sku
                }
                ... on BundleCartItem {
                  bundle_options {
                    uid
                    label
                    type
                    values {
                      id
                      label
                      price
                      quantity
                    }
                  }
                }
              }
            }
          }
        }
      `, showLoader, useCustomerToken);
      return data;
    }

    const downloadableLink = item.downloadable_product_links[0];

    const data = await this.graphql.mutate(`
    {
      addDownloadableProductsToCart(
        input: {
          cart_id: "${cartId}"
          cart_items: {
            data: {
              sku: "${item.sku}"
              quantity: 1
            }
            downloadable_product_links: [
              {
                link_id: ${downloadableLink.id}
              }
            ]
          }
        }
      ) {
        cart {
          id
          total_quantity
          items {
            quantity
            id
            product {
              sku
            }
          }
        }
      }
    }
    `, showLoader, useCustomerToken);
    return data;
  }

  async removeFromCart(item: any) {
    const data = await this.graphql.mutate(`
      {
        removeItemFromCart(
          input: {
            cart_id: "${item.inCart.cartId}",
            cart_item_id: ${item.inCart.itemId}
          }
        ) {
          cart {
            id
            total_quantity
            items {
              quantity
              id
              product {
                sku
              }
            }
          }
        }
      }
    `);
    return data;
  }

  async oneClickCheckout(item) {
    const loggedIn = await this.checkLoggedIn();
    const cartId = await this.getCartId(true);
    await this.addToCart(item, false);
    const order = await this.checkout(cartId);

    const orderNumber = order.data.placeOrder.order.order_number;
    const orderDetails = await this.getOrder(loggedIn ? orderNumber : cartId);
    await this.storage.remove('cart_id');
    return orderDetails
  }

  async getOrder(id: string) {
    const loggedIn = await this.checkLoggedIn();
    let graphql = `
      {
        graycoreGuestOrders(cartId: "${id}") {
          items {
            id
            items {
              id
              product_sku
              product_type
              product_name
              product_url_key
              ... on DownloadableOrderItem {
                link_hashes
              }
              ... on BundleOrderItem {
                bundle_options {
                  id
                  uid
                  label
                }
              }
            }
          }
        }
      }
    `;
    if (loggedIn) {
      graphql = `
        {
          customer {
            orders(filter: {number: {eq: "${id}"}}) {
              total_count
              items {
                id
                items {
                  id
                  product_sku
                  product_type
                  product_name
                  product_url_key
                  ... on DownloadableOrderItem {
                    link_hashes
                  }
                  ... on BundleOrderItem {
                    bundle_options {
                      id
                      uid
                      label
                      values {
                        id
                        uid
                        product_name
                        product_sku
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `;
    }
    const data = await this.graphql.query(graphql, false, true);
    return data?.data?.graycoreGuestOrders || data?.data?.customer?.orders;
  }
  async checkout(cartId: string) {
    const loggedIn = await this.checkLoggedIn();
    let firstname: string = 'Guest';
    let lastname: string = 'User';

    let customerInfoMutation = `
      setGuestEmailOnCart(
        input: { cart_id: "${cartId}", email: "guest@brainwashed.co.za" }
      ) {
        cart {
          id
        }
      }
    `;
    if (loggedIn) {
      customerInfoMutation = '';
      const customerInfo = await this.storage.get('customer_info');
      firstname = customerInfo.firstname;
      lastname = customerInfo.lastname;

    }
    let graphql = `
      {
        ${customerInfoMutation}
        setBillingAddressOnCart(
          input: {
            cart_id: "${cartId}"
            billing_address: {
              address: {
                firstname: "${firstname}"
                lastname: "${lastname}"
                street: ["33 Martin Hammerschlag Way", "Cape Town City Centre"]
                city: "Cape Town"
                region: "Western Cape"
                postcode: "8000"
                country_code: "ZA"
                telephone: "+27214876800"
                save_in_address_book: false
              }
            }
          }
        ) {
          cart {
            billing_address {
              firstname
              lastname
              company
              street
              city
              region{
                code
                label
              }
              postcode
              telephone
              country {
                code
                label
              }
            }
          }
        }
        setPaymentMethodOnCart(
          input: {
            cart_id: "${cartId}",
            payment_method: { code: "free" }
          }
        ) {
          cart {
            selected_payment_method {
              code
            }
          }
        }
        placeOrder(input: {cart_id: "${cartId}"}) {
          order {
            order_number
          }
        }
      }
    `;
    let data = await this.graphql.mutate(graphql, false, true);
    if (data?.errors) {
      const error = data.errors.shift();
      if (error?.extensions?.category === 'graphql-authorization') {
        const convertedCartId = await this.mergeCart(cartId);
        return await this.checkout(convertedCartId);
      }
      if (error?.extensions?.category === 'graphql-no-such-entity') {
        await this.storage.remove('cart_id');
        this.itemCount = 0;
      }
    }
    this.customer.init();
    return data;
  }
}
