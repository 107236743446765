import { Injectable } from '@angular/core';
import { GraphqlService } from '../utils/graphql.service';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  graphql: GraphqlService;
  storage: Storage = null;

  maintenance: boolean = false;

  defaultStoreConfig: {[k: string]: any} = {};

  storeConfig = {
    loaded: false,
    base_url: null,
    base_media_url: null,
    header_logo_src: null,
    logo_height: null,
    logo_width: null,
    logo_alt: null,
    copyright: null,
    pdf_viewer_url: null,
    downloadable_link_url: null,
    trans_email_ident_general_email: null,
    trans_email_ident_general_name: null,
    trans_email_ident_sales_email: null,
    trans_email_ident_sales_name: null,
    trans_email_ident_support_email: null,
    trans_email_ident_support_name: null,
    trans_email_ident_custom1_email: null,
    trans_email_ident_custom1_name: null,
    trans_email_ident_custom2_email: null,
    trans_email_ident_custom2_name: null,
    contact_email_sender_email_identity: null,
    google_maps_api_key: null
  };

  cart = {
    itemCount: 0
  }

  pageInfo = {
    title: null
  }

  productsAttributes = [];

  constructor(
    graphql: GraphqlService,
    storage: Storage
  ) {
    this.graphql = graphql;
    this.storage = storage;
    if (this.storeConfig.loaded === false) {
      this.init();
    }
  }

  async init() {
    let storeConfig = await this.graphql.query(`
      {
        storeConfig {
          store_code
          store_name
          is_default_store
          store_group_code
          is_default_store_group
          locale
          base_currency_code
          default_display_currency_code
          timezone
          base_url
          base_link_url
          base_static_url
          base_media_url
          secure_base_url
          secure_base_link_url
          secure_base_static_url
          secure_base_media_url
          welcome
          head_shortcut_icon
          header_logo_src
          logo_height
          logo_width
          logo_alt
          copyright
          trans_email_ident_general_email
          trans_email_ident_general_name
          trans_email_ident_sales_email
          trans_email_ident_sales_name
          trans_email_ident_support_email
          trans_email_ident_support_name
          trans_email_ident_custom1_email
          trans_email_ident_custom1_name
          trans_email_ident_custom2_email
          trans_email_ident_custom2_name
          contact_email_sender_email_identity
          google_maps_api_key
        }
        availableStores(useCurrentGroup: true) {
          store_code
          store_name
          is_default_store
          store_group_code
          is_default_store_group
          locale
          base_currency_code
          default_display_currency_code
          timezone
          weight_unit
          base_url
          base_link_url
          base_static_url
          base_media_url
          secure_base_url
          secure_base_link_url
          secure_base_static_url
          secure_base_media_url
        }
      }
    `, true, true, true);
    if (!storeConfig?.data) {
      this.maintenance = true;
      return;
    }
    this.maintenance = false;
    const defaultStore = storeConfig.data.availableStores.find(store => store.is_default_store);
    this.storeConfig = storeConfig.data.storeConfig;
    this.storeConfig.copyright = this.storeConfig.copyright.replace("\n", "<br />");
    this.storeConfig.pdf_viewer_url = defaultStore.base_url + 'pdf/viewer/web/viewer.html?file=';
    this.storeConfig.downloadable_link_url = defaultStore.base_url + 'downloadable/download/link/id/';
    this.storeConfig.loaded = true;
    this.defaultStoreConfig = defaultStore;

    let productAttributes = await this.graphql.query(`
      {
        customAttributeMetadata(
          attributes: [
            {
              attribute_code: "location"
              entity_type: "catalog_product"
            },
            {
              attribute_code: "destination"
              entity_type: "catalog_product"
            },
            {
              attribute_code: "region"
              entity_type: "catalog_product"
            },
            {
              attribute_code: "country"
              entity_type: "catalog_product"
            },
            {
              attribute_code: "file_format"
              entity_type: "catalog_product"
            },
            {
              attribute_code: "language"
              entity_type: "catalog_product"
            }
          ]
        ) {
          items {
            attribute_code
            attribute_type
            entity_type
            input_type
            attribute_options {
              value
              label
            }
            storefront_properties {
              use_in_product_listing
              use_in_layered_navigation
              use_in_search_results_layered_navigation
              visible_on_catalog_pages
              position
            }
          }
        }
      }
    `, false, false, true);
    if (productAttributes?.data?.customAttributeMetadata?.items) {
      this.productsAttributes = productAttributes.data.customAttributeMetadata.items;
    }

    this.prep();
  }

  prep() {
    if (this.storeConfig.google_maps_api_key && !window?.google?.maps) {
      let node = document.createElement('script');
      node.src = 'https://maps.googleapis.com/maps/api/js?v=3&key=' + this.storeConfig.google_maps_api_key;
      node.type = 'text/javascript';
      node.async = true;
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  productView(item: any) {

    fetch(environment.backendUrl + item.url_key + '?proxy=false', {
        method: 'GET'
      })
      .catch(e => {
        console.warn(e);
      });
  }

  stringToHtml(htmlString: string) {
    const domParser = new DOMParser();
    return domParser.parseFromString(htmlString, 'text/html');
  }

  mediaUrl(url: string): string {
    let returnUrl = url.replace(this.storeConfig.base_url, '/');
    if (returnUrl.indexOf('/media') === 0) {
      returnUrl = returnUrl.replace('/media', '');
    }

    if (returnUrl.indexOf('http') !== 0) {
      returnUrl = this.storeConfig.base_media_url + returnUrl;
    }
    return returnUrl;
  }

  localDateTime(dateString: string, timezone: string = 'GMT'): string {
    const dateWithTimeZone = dateString.split('-').join('/') + ' ' + timezone;
    const formattedDate = new Date(dateWithTimeZone);
    return formattedDate.toLocaleString();
  }

  getContact(): { email: string, name: string } {
    const contactIdentity = this.storeConfig.contact_email_sender_email_identity;
    const contact = {
      email: this.storeConfig['trans_email_ident_' + contactIdentity + '_email'],
      name: this.storeConfig['trans_email_ident_' + contactIdentity + '_name']
    }
    return contact;
  }

  async generateIcs(event, includeEventContact = false) {
    const privateSession = event.privateSession ? new Date(event.selectedSlot.date) : null;
    const customer = await this.storage.get('customer_info');
    let eventDuration = (event.product?.event_duration ? event.product.event_duration : 60) * 60 * 1000;
    let eventAttendee = `CUTYPE=INDIVIDUAL;ROLE=REQ-PARTICIPANT;PARTSTAT=ACCEPTED;RSVP=FALSE;CN=${customer.firstname} ${customer.lastname};X-NUM-GUESTS=0:mailto:${customer.email}`
    let organiser = `ORGANIZER;CN=${this.getContact().name};SENT-BY="mailto:${this.getContact().email}":mailto:${this.getContact().email}`;
    let message = 'You have reserved a seat for ' + event.label;
    if (!privateSession && includeEventContact) {
      message = `You have reserved a meeting with ${event.product.event_contact} for ${event.label}${event.product?.short_description?.html ? ' at ' + event.product?.short_description?.html : ''}`;
      eventAttendee = 'ATTENDEE;' + eventAttendee;
      eventAttendee += `\nATTENDEE;CUTYPE=INDIVIDUAL;ROLE=REQ-PARTICIPANT;PARTSTAT=ACCEPTED;RSVP=FALSE;CN=${event.product.event_contact};X-NUM-GUESTS=0:mailto:${event.product.event_contact_email}`;
    }
    if (privateSession) {
      const pieces = event.selectedSlot.title.split(' (');
      const privateSessionHost = {
        name: pieces.shift().trim(),
        email: pieces.pop().replace(')')
      };
      eventDuration = 30 * 60 * 1000;
      eventAttendee += `\nATTENDEE;CUTYPE=INDIVIDUAL;ROLE=REQ-PARTICIPANT;PARTSTAT=ACCEPTED;RSVP=FALSE;CN=${event.product.event_contact};X-NUM-GUESTS=0:mailto:${event.product.event_contact_email}`;
      eventAttendee += `\nATTENDEE;CUTYPE=INDIVIDUAL;ROLE=REQ-PARTICIPANT;PARTSTAT=ACCEPTED;RSVP=FALSE;CN=${privateSessionHost.name};X-NUM-GUESTS=0:mailto:${privateSessionHost.email}`;

      message = 'Your private session for ' + event.label;
    }
    const timeStampDate = new Date();
    const timezone = new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)[1];
    let timeStamp = `${timeStampDate.getFullYear()}`;
    timeStamp += `${timeStampDate.getMonth() + 1 < 10 ? '0' : ''}`;
    timeStamp += `${timeStampDate.getMonth() + 1}`;
    timeStamp += `${timeStampDate.getDate() + 1 < 10 ? '0' : ''}`;
    timeStamp += `${timeStampDate.getDate()}T`;
    timeStamp += `${timeStampDate.getHours() < 10 ? '0' : ''}`;
    timeStamp += `${timeStampDate.getHours()}`;
    timeStamp += `${timeStampDate.getMinutes() < 10 ? '0' : ''}`;
    timeStamp += `${timeStampDate.getMinutes()}`;
    timeStamp += `${timeStampDate.getSeconds() < 10 ? '0' : ''}`;
    timeStamp += `${timeStampDate.getSeconds()}`;

    const eventStartDate = privateSession || new Date(event.product.event_date + ' GMT');
    let dateStart = `${eventStartDate.getFullYear()}`;
    dateStart += `${eventStartDate.getMonth() + 1 < 10 ? '0' : ''}`;
    dateStart += `${eventStartDate.getMonth() + 1}`;
    dateStart += `${eventStartDate.getDate() + 1 < 10 ? '0' : ''}`;
    dateStart += `${eventStartDate.getDate()}T`;
    dateStart += `${eventStartDate.getHours() < 10 ? '0' : ''}`;
    dateStart += `${eventStartDate.getHours()}`;
    dateStart += `${eventStartDate.getMinutes() < 10 ? '0' : ''}`;
    dateStart += `${eventStartDate.getMinutes()}`;
    dateStart += `${eventStartDate.getSeconds() < 10 ? '0' : ''}`;
    dateStart += `${eventStartDate.getSeconds()}`;

    const eventEndDate = privateSession || new Date(event.product.event_date + ' GMT');
    eventEndDate.setTime(eventEndDate.getTime() + eventDuration);
    let dateEnd = `${eventEndDate.getFullYear()}`;
    dateEnd += `${eventEndDate.getMonth() + 1 < 10 ? '0' : ''}`;
    dateEnd += `${eventEndDate.getMonth() + 1}`;
    dateEnd += `${eventEndDate.getDate() + 1 < 10 ? '0' : ''}`;
    dateEnd += `${eventEndDate.getDate()}T`;
    dateEnd += `${eventEndDate.getHours() < 10 ? '0' : ''}`;
    dateEnd += `${eventEndDate.getHours()}`;
    dateEnd += `${eventEndDate.getMinutes() < 10 ? '0' : ''}`;
    dateEnd += `${eventEndDate.getMinutes()}`;
    dateEnd += `${eventEndDate.getSeconds() < 10 ? '0' : ''}`;
    dateEnd += `${eventEndDate.getSeconds()}`;
    let uid = `UID:${btoa(event.product.sku)}-${event.uid}-${btoa(customer.email)}-${btoa(dateStart)}-${btoa(dateEnd)}`;
    uid = uid.replace(/=/g, '');

    const content = `BEGIN:VCALENDAR
PRODID:-//BW//Communication//_Scheduler//EN
NAME: Get Connected
X-WR-CALNAME:Get Connected
TIMEZONE-ID:${timezone}
X-WR-TIMEZONE:${timezone}
VERSION:2.0
CALSCALE:GREGORIAN
METHOD:REQUEST
BEGIN:VEVENT
DTSTART;TZID=${timezone}:${dateStart}
DTEND;TZID=${timezone}:${dateEnd}
DTSTAMP:${timeStamp}Z
${organiser}
${uid}
${eventAttendee}
DESCRIPTION:${event.label}
LAST-MODIFIED:${timeStamp}Z
LOCATION:${event.product.event_url}
SEQUENCE:0
STATUS:TENTATIVE
SUMMARY;LANGUAGE=en-us:${event.label}
TRANSP:OPAQUE
END:VEVENT
END:VCALENDAR`
    const additionalData: {[k: string]: any} = {
      subject: event.label + ' | ' + event.product.event_contact + `${event.product?.short_description?.html ? ' at ' + event.product?.short_description?.html : ''}`,
      message: message,
      template_id: environment.getConnectedTemplateId,
      name: customer.firstname + ' ' + customer.lastname,
      meta_title: event.product?.meta_title || 'Meet South Africa',
      attachment: {
        label: 'invite.ics',
        mime: 'text/calendar;method=REQUEST;name=\'invite.ics\'',
        value: btoa(content)
      }
    }
    if (!privateSession && includeEventContact) {
      additionalData.ccEventContact = {
        type: 'cc',
        label: event.product.event_contact,
        value: event.product.event_contact_email
      }
    }    
    if (privateSession) {
      additionalData.ccEventContact = {
        type: 'cc',
        label: event.product.event_contact,
        value: event.product.event_contact_email
      }
    }
    await this.contactUs(
      {
        email: customer.email,
        name: customer.firstname + ' ' + customer.lastname
      },
      this.getContact(),
      additionalData
    );
    return content;
  }

  async contactUs(
    to = { email: null, name: null },
    from = { email: null, name: null },
    additionalData: {[k: string]: any}
  ) {
    const ad = [];
    for (let key of Object.keys(additionalData)) {
      const value = additionalData[key];
      if (typeof(value) === 'object') {
        let parsedString = [];
        parsedString.push(`type: "${key}"`);
        for (let k of Object.keys(value)) {
          parsedString.push(`${k}: "${value[k]}"`);
        }
        ad.push("{\n" + parsedString.join("\n") + "\n}");
        continue;
      }
      ad.push(`
        {
          label: "${key}"
          value: "${value.toString().trim()}"
        }
      `);
    }
    let adString = '';
    if (ad.length > 0) {
      adString = 'additionalData: [' + ad.join("\n")+ ']';
    }

    let toString = '';
    toString += to?.email ? "email: \"" + to.email + "\"": '';
    toString += to?.name ? "\nname: \"" + to.name + "\"": '';
    if (toString) {
      toString = "to: {\n" + toString + "\n}\n";
    }
    let fromString = '';
    fromString += from?.email ? "email: \"" + from.email + "\"" : '';
    fromString += from?.name ? "\nname: \"" + from.name + "\"" : '';
    if (fromString) {
      fromString = "from: {\n" + fromString + "\n}\n";
    }

    return await this.graphql.mutate(`
      {
        email(
          input: {
            ${toString}
            ${fromString}
            ${adString}
          }
        ) {
          status
        }
      }
    `);
  }
}
