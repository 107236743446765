import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/utils/customer.service';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'bw-customer-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  customer: CustomerService = null;
  toast: ToastController = null;

  selectedSection: string = '';

  customerData: {[k: string]: any} = {
    email: null,
    newsletter: null,
    firstname: null,
    lastname: null,
    company: null,
    position: null
  }
  errors: {[k: string]: any} = {};

  constructor(
    customer: CustomerService,
    toast: ToastController
  ) {
    this.customer = customer;
    this.toast = toast;
  }

  ngOnInit() {
    this.customer.subject.subscribe(async (data) => {
      if (!data?.data?.customer) {
        return;
      }

      if (!data.data.customer?.company || !data.data.customer?.position) {
        this.toggleDetails(true);
        this.customer.subject.next('showMenu');
        if (data.data.customer?.is_new) {
          return;
        }
        const toast = await this.toast.create({
          header: 'Events',
          message: 'Please complete your profile with your company name and your position.',
          icon: 'close-circle',
          color: 'danger',
          duration: 2000
        });
        toast.present();
      }
    });
    this.init();

  }

  async init() {
    const customerData = await this.customer.storage.get('customer_info');
    this.customerData.firstname = customerData.firstname;
    this.customerData.lastname = customerData.lastname;
    this.customerData.email = customerData.email;
    this.customerData.company = customerData.company;
    this.customerData.position = customerData.position;
    this.customerData.newsletter = customerData.is_subscribed;
    this.customerData.is_new = customerData?.is_new;
    this.customer.subject.next({
      data: {
        customer: this.customerData
      }
    });
  }

  updateEmail(event) {
    this.customerData.email = event.target.value;
  }
  updateFirstname(event) {
    this.customerData.firstname = event.target.value;
  }
  updateLastname(event) {
    this.customerData.lastname = event.target.value;
  }
  updateCompany(event) {
    this.customerData.company = event.target.value;
  }
  updatePosition(event) {
    this.customerData.position = event.target.value;
  }
  updateNewsletter(event) {
    this.customerData.newsletter = event.detail.checked;
  }

  async toggleDetails(update:boolean = false) {
    let detailList:HTMLElement = document.querySelector('.customer-account-update');
    while (!detailList) {
      await new Promise(r => setTimeout(r, 500));
      detailList = document.querySelector('.customer-account-update');
    }
    if (update) {
      this.selectedSection = 'details';
    } else {
      this.selectedSection = this.selectedSection === 'details' ? '' : 'details';
    }

    detailList.style.display = this.selectedSection === 'details' ? 'block' : 'none';
    if (update) {
      await new Promise(r => setTimeout(r, 1000));
      this.update();
    }
  }

  async update() {
    this.errors = {};
    if (!this.customerData.firstname) {
      this.errors['firstname'] = 'Firstname is required.';
    }
    if (!this.customerData.lastname) {
      this.errors['lastname'] = 'Lastname is required.';
    }
    if (!this.customerData.email) {
      this.errors['email'] = 'Email is required.';
    }
    if (!this.customerData.company) {
      this.errors['company'] = 'Company is required.';
    }
    if (!this.customerData.position) {
      this.errors['position'] = 'Position is required.';
    }
    if (Object.keys(this.errors).length > 0) {
      return;
    }
    const data = await this.customer.update(this.customerData);
    if (data?.data?.updateCustomerV2?.customer) {
      const toast = await this.toast.create({
        header: 'My Account',
        message: `Details updated successfully.`,
        icon: 'checkmark-circle',
        color: 'success',
        duration: 2000
      });
      toast.present();
    }
  }

  async logout() {
    await this.customer.logout();
    const toast = await this.toast.create({
      header: 'My Account',
      message: `Logged out successfully.`,
      icon: 'checkmark-circle',
      color: 'success',
      duration: 2000
    });
    toast.present();
    this.customer.subject.next({});
  }
}
