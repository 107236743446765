import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GraphqlService } from 'src/app/utils/graphql.service';
import { CustomerService } from 'src/app/utils/customer.service';
import { MiscService } from 'src/app/utils/misc.service';
import { IonSegment, IonMenu } from '@ionic/angular';
@Component({
  selector: 'bw-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  @Input() menu = null;
  @ViewChild('menuSegment') menuSegmentRef: IonSegment;
  @ViewChild('sideMenu') menuRef: IonMenu;

  graphql: GraphqlService = null;
  customer: CustomerService = null;
  misc: MiscService = null;

  selectedSegment: string = 'menu';
  loggedIn: boolean = false;
  loginModalState: any = {
    showLogin: false
  }

  constructor(
    graphql: GraphqlService,
    customer: CustomerService,
    misc: MiscService
  ) {
    this.graphql = graphql;
    this.customer = customer;
    this.misc = misc;
  }

  async ngOnInit() {
    this.misc.subject.subscribe((data) => {
      if (data === 'showLogin') {
        this.loginModalState.showLogin = true;
      }
      if (data === 'showRegister') {
        this.loginModalState.showLogin = true;
      }
      if (data === 'hideLogin') {
        this.loginModalState.showLogin = false;
        this.checkLoggedIn();
      }
    });
    this.customer.subject.subscribe((data) => {
      if (data === 'showMenu') {
        this.menuRef.open();
        return;
      }
      this.checkLoggedIn();
    });
    await this.checkLoggedIn();
    if (document.location.hash === '#register') {
      this.misc.showRegister();
    }
  }

  async checkLoggedIn() {
    const token = await this.customer.storage.get('customer_token');
    this.loggedIn = token ? true : false;
    if (!this.loggedIn) {
      this.selectedSegment = 'menu';
      this.menuSegmentRef.value = 'menu';
      return;
    }
    this.selectedSegment = 'account';
    this.menuSegmentRef.value = 'account';
  }

  segmentChanged(ev: any) {
    if (ev.detail.value === 'account' && !this.loggedIn) {
      this.misc.subject.next('showLogin');
      return;
    }
    this.selectedSegment = ev.detail.value;
  }
}
