import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from 'src/app/utils/customer.service';
import { IonModal, IonSegment, ToastController } from '@ionic/angular';
import { MiscService } from 'src/app/utils/misc.service';
@Component({
  selector: 'bw-customer-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  @ViewChild('loginModal') loginModalRef: IonModal;
  @ViewChild('loginSegment') loginSegmentRef: IonSegment;

  customer: CustomerService = null;
  toast: ToastController = null;
  misc: MiscService = null;

  selectedSection: string = '';

  customerData: {[k: string]: any} = {
    email: null,
    password: null,
    confirm_password: null,
    newsletter: true,
    firstname: null,
    lastname: null
  }
  errors: {[k: string]: any} = {};

  forgotPassword: boolean = false;

  selectedSegment: string = 'login';


  constructor(
    customer: CustomerService,
    toast: ToastController,
    misc: MiscService
  ) {
    this.customer = customer;
    this.toast = toast;
    this.misc = misc;
  }

  async ngOnInit() {
    if (this.misc.details?.selectedSegment) {
      this.selectedSegment = this.misc.details.selectedSegment;
      this.misc.details.selectedSegment = null;
    }
  }

  async initSection() {
    if (this.selectedSegment === 'register') {
      this.loginSegmentRef.value = 'register'
    }
  }

  segmentChanged(ev: any) {
    this.selectedSegment = ev.detail.value;
  }  

  closeLoginModal() {
    this.loginModalRef.dismiss();
  }

  toggleLogin() {
    this.selectedSection = this.selectedSection === 'login' ? '' : 'login';
    let loginList:HTMLElement = document.querySelector('.customer-account-login');
    loginList.style.display = this.selectedSection === 'login' ? 'block' : 'none';
    let registerList:HTMLElement = document.querySelector('.customer-account-register');
    registerList.style.display = 'none';
  }

  toggleRegister() {
    this.selectedSection = this.selectedSection === 'register' ? '' : 'register';
    let registerList:HTMLElement = document.querySelector('.customer-account-register');
    registerList.style.display = this.selectedSection === 'register' ? 'block' : 'none';
    let loginList:HTMLElement = document.querySelector('.customer-account-login');
    loginList.style.display = 'none';
  }

  async login() {
    this.errors = {};
    if (!this.customerData.email) {
      this.errors.email = 'This field is required.';
    }
    if (!this.customerData.password) {
      this.errors.password = 'This field is required.';
    }
    if (Object.keys(this.errors).length > 0) {
      return;
    }

    let data = await this.customer.login(this.customerData);
    if (data?.errors) {
      const error = data.errors.pop();

      const toast = await this.toast.create({
        header: 'Login',
        message: error.message,
        icon: 'close-circle',
        color: 'danger',
        duration: 6000
      });
      toast.present();
      return;
    }
    this.misc.loggedIn();
    this.closeLoginModal();
  }

  async register() {

    this.errors = {};
    if (!this.customerData.firstname) {
      this.errors.firstname = 'This field is required.';
    }
    if (!this.customerData.lastname) {
      this.errors.lastname = 'This field is required.';
    }
    if (!this.customerData.email) {
      this.errors.email = 'This field is required.';
    }
    if (!this.customerData.password) {
      this.errors.password = 'This field is required.';
    }
    if (!this.customerData.confirm_password) {
      this.errors.confirm_password = 'This field is required.';
    }
    if (Object.keys(this.errors).length > 0) {
      return;
    }

    let data = await this.customer.register(this.customerData);
    if (data?.errors) {

      let messages = [];
      for (const error of data.errors) {
        messages.push(error.message);
      }      

      const toast = await this.toast.create({
        header: 'Register',
        message: messages.join(' '),
        icon: 'close-circle',
        color: 'danger',
        duration: 6000
      });
      toast.present();
      return;
    }

    const toast = await this.toast.create({
      header: 'Register',
      message: 'Registration successful. Please complete your profile with your company name and your position.',
      icon: 'checkmark-circle',
      color: 'success',
      duration: 6000
    });
    toast.present();

    this.customerData = {};
    this.closeLoginModal();

  }

  updateEmail(event) {
    this.customerData.email = event.target.value;
  }
  updateFirstname(event) {
    this.customerData.firstname = event.target.value;
  }
  updateLastname(event) {
    this.customerData.lastname = event.target.value;
  }
  updatePassword(event) {
    this.customerData.password = event.target.value;
  }
  updateConfirmPassword(event) {
    this.customerData.confirm_password = event.target.value;
  }
  updateNewsletter(event) {
    this.customerData.newsletter = event.detail.checked;
  }

  async resetPassword() {
    this.errors = {};

    if (!this.customerData.email) {
      this.errors.email = 'Please supply a registered email address.';
    }
    if (Object.keys(this.errors).length > 0) {
      return;
    }

    const data = await this.customer.requestPasswordReset(this.customerData);

    if (data?.errors) {
      let messages = [];
      for (const error of data.errors) {
        messages.push(error.message);
      }
      const toast = await this.toast.create({
        header: 'Password Reset',
        message: messages.join(' '),
        icon: 'close-circle',
        color: 'danger',
        duration: 6000
      });
      toast.present();
      return;
    }

    const toast = await this.toast.create({
      header: 'Password Reset',
      message: `Your password reset request was sent. Please check your email.`,
      icon: 'checkmark-circle',
      color: 'success',
      duration: 2000
    });
    toast.present();
    this.forgotPassword = false;
  }

  onWillDismiss() {
    this.misc.subject.next('hideLogin');
  }  
}
