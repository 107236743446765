import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { GraphqlService } from 'src/app/utils/graphql.service';
import { CoreService } from './utils/core.service';
import { MiscService } from './utils/misc.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  private storage: Storage = null;
  private graphql: GraphqlService = null;
  public core: CoreService = null;
  private misc: MiscService = null;
  private router: Router = null;

  categories = null;

  constructor(
    storage: Storage,
    router: Router,
    graphql: GraphqlService,
    core: CoreService,
    misc: MiscService
  ) {
    this.storage = storage;
    this.router = router;
    this.graphql = graphql;
    this.core = core;
    this.misc = misc;
    this.storage.create();
    this.getCategories();
  }

  async ngOnInit() {
    window.gtag('config', environment.gaId);

    this.misc.subject.subscribe(message => {
      if (message === 'loggedIn' || message === 'loggedOut') {
        this.getCategories();
        if (message === 'loggedOut') {
          this.router.navigateByUrl('/');
        }
      }
    });
  }

  async getCategories() {
    const categories = await this.graphql.query(`
      query GetCategories(
        $filter: CategoryFilterInput
        $pageSize: Int
        $currentPage: Int
      ) {
        categories(filters: $filter, pageSize: $pageSize, currentPage: $currentPage) {
          total_count
          items {
            ...ChildProperties
            children {
              ...ChildProperties
              children {
                ...ChildProperties
                children {
                  ...ChildProperties
                  children {
                    ...ChildProperties
                    children {
                      ...ChildProperties
                    }
                  }
                }
              }
            }
          }
          page_info {
            current_page
            page_size
            total_pages
          }
        }
      }

      fragment ChildProperties on CategoryTree {
        id
        uid
        name
        url_key
        url_path
        product_count
        position
        level
        include_in_menu
        display_mode
        path
        image
      }
    `, false, true, true);
    this.categories = categories.data;
  }
}
